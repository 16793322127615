import {
  List,
  Datagrid,
  TextField,
  NumberField,
  NumberInput,
  Edit,
  TextInput,
  Filter,
  TabbedForm,
  FormTab,
  BooleanInput,BooleanField
} from "react-admin";

export const Linked_Unit_QueueEdit = (props: any) => (
    <Edit {...props} title="Изменение параметров очереди">
      <TabbedForm>
        <FormTab label="Параметры очереди">
          <div>
            <NumberInput disabled source="id" label="Id очереди" />
          </div>
          <div>
            <NumberInput disabled source="Unit_Id" label="№ Юнита" />
            <TextInput disabled source="Unit_Name" label="Наименование Юнита" /> )
          </div>

          <TextInput
            source="Queue_Name"
            label="Наименование очереди"
            name="Queue_Name"
          />
          <div>

            <BooleanInput disabled source="Is_Alternatative" label="признак ветвления" />
          </div>
          <TextInput source="Link_Queue_Name" label="связь с интеграцией" helperText="коды из полей`eventCode` и `p_dept_id`"/>
          <div>
            <TextInput disabled source="Label_Q_To" label="Очередь, куда перенаправляется далее" />
          </div>
        </FormTab>
      </TabbedForm>
    </Edit>
  );

const Linked_Unit_QueueFilter = (props: any) => (
  <Filter {...props}>
    <TextInput
      source="Queue_NameQueue_Name"
      label="Наименование очереди"
      alwaysOn
      name="Queue_Name"
    />
    <TextInput source="Unit_Name" label="Наименование Юнита" alwaysOn />
    <TextInput source="Label_Q_From" label="Очередь откуда" alwaysOn />
  </Filter>
);

export const Linked_Unit_QueueList = ({ ...props }) => (
  <List
    {...props}
    title="Контроль за переходами между очередями Юнитов"
    // eslint-disable-next-line react/jsx-pascal-case
    filters={<Linked_Unit_QueueFilter />}
    sort={{ field: "Unit_Id", order: "ASC" }}
    perPage={25}
  >
    <Datagrid isRowSelectable={() => false}>

      <NumberField source="Unit_Id" label="№ Юнита" />
      <TextField source="Unit_Name" label="Наименование Юнита" />
      <NumberField source="Label_Q_From" label="Очередь-откуда" />
      <TextField source="Queue_Name" label="Наименование очереди откуда" />
      <NumberField source="Label_Q_To" label="Очередь-куда" />
      <TextField source="Link_Queue_Name" label="Наименование очереди куда" />
      <BooleanField source="Is_Alternatative" label="признак ветвления" />
    </Datagrid>
  </List>
);
