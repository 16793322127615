import type { FC } from "react";
import { Create, SimpleForm, NumberInput, TextInput } from "react-admin";

export const CreatePlace: FC = (props: any) => (
  <Create {...props} title="Создание нового месторасположения группы рабочих мест в мед.учреждении">
    <SimpleForm>
      <NumberInput label="ID места" source="Place_Id" required helperText="Уникальное число - идентификатор"/>
      <TextInput label="Наименование месторасположения группы рабочих мест" source="Name" required />
      <NumberInput label="Число рабочих мест (необязательно)" source="Qty_Work_Places"  />
      <TextInput label="Тип" source="Target_Name" required helperText="Краткое название рабочих мест, например, ОКНО, КАБИНЕТ..."/>
    </SimpleForm>
  </Create>
);
