
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    DateField,
    TextInput,
    Filter,
    NumberInput, DateInput
} from 'react-admin';

//import { sizing } from '@material-ui/system';
// import {Box} from "@mui/material";

//import { makeStyles } from "@mui/styles"

//const useStyles = makeStyles({
//    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
//});


const StatReportAsFilter = (props: any) => (
    <Filter {...props}>
        <TextInput label="№-Талона" source="Emias_Pass_Num" alwaysOn />
        <TextInput source="Kis_Card_Id" label="№ ИБ" alwaysOn />
        <TextInput source="U_Login" label="Эндоскопист"  alwaysOn/>
        <TextInput source="Worker_Place_Num" label="№ процедурной"/>
        <TextInput source="U_Login_Therapist" label="Гастроэнтеролог" alwaysOn/>
        <DateInput source="Hospitalization" label="Гоститализация" alwaysOn/>
        <NumberInput label="Order_Id" source="id" alwaysOn/>
    </Filter>
);

export const StatReportA = ({ ...props }) => <List
    { ...props } title="Отчет за 5 дней" perPage={100} filters={<StatReportAsFilter /> }
    sort={{ field: 'id', order: 'ASC' }}
>
    <Datagrid isRowSelectable={() => false } >

        <NumberField source="id" label="Order_Id"/>
        <TextField source="Emias_Pass_Num" label="№-Талона"/>
        <DateField source="Emias_Order_Date" label="Дата записи" showTime/>
        <TextField source="Kis_Card_Id" label="№ ИБ"/>
        <TextField source="Kis_Service_Name" label="Услуга"/>
        <DateField source="Hospitalization" label="Гоститализация" showTime/>
        <DateField source="In_Q_4_Procedures" label="в Очереди на процедуру" showTime/>
        <DateField source="In_Work_Procedures" label="Вызван на процедуру" showTime/>
        <DateField source="Finish_Work_Procedures" label="Завершение процедуры" showTime/>
        <TextField source="U_Login" label="Эндоскопист"  />
        <TextField source="Worker_Place_Num" label="№ процедурной"/>
        <DateField source="In_Q_4_Therapist" label="в Очереди к врачу" showTime/>
        <DateField source="In_Work_Therapist" label="Вызван к врачу" showTime/>
        <DateField source="Finish_Work_Therapist" label="Завершение консультаии" showTime/>
        <TextField source="U_Login_Therapist" label="Гастроэнтеролог"/>

    </Datagrid>
</List>;


