import React, { useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  NumberField,
  //DateField,
  Edit,
  Create,
  SimpleForm,
  NumberInput,
  TextInput,
  TopToolbar,
  ListButton,
  Filter,
  SelectInput,
  Toolbar,
} from "react-admin";
import MuiAlert from "@mui/material/Alert";
import { Typography } from "@mui/material";
//import { ChangePasword } from './ChangePasword';
import { CreateWorker } from "./CreateWorkerAll";
//import { WorkplacesList } from './WorkplacesList';
import { EditWorker } from "./EditWorkerAll";

const roleChoices = [
  { id: 1, name: "SysAdmin" },
  { id: 2, name: "Бизнес-администратор" },
  { id: 3, name: "Супервизор UNNIT " },
  { id: 4, name: "Исполнитель Услуги" },
  { id: 5, name: "Табло" },
];

const WorkerEditToolbar = (props: any) => {
  console.log({ props });

  return (
    <Toolbar {...props}>
      <></>
    </Toolbar>
  );
};

export const WorkPlaceFilter = (props: any) => {
  console.log("WorkPlaceFilter", props);
  return (
    <Filter {...props}>
      <NumberInput source="id" alwaysOn />
    </Filter>
  );
};

//  const EditAside = (props: any) => {
//     //const {id, login, alertSet} = props;
//     const {id} = props;

//     console.log(id);

//     return (<div style={{ width: 200, margin: '1em' }}>
//         <Typography variant="h6">Смена пароля</Typography>
//         <Typography variant="body2">
//             Введите новый пароль для текущего пользователя
//         </Typography>
//     </div>)
//  }

/**
        <ChangePasword login={login} id={id} 
            alertSet={alertSet}
            {...props} 
         />
 */

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const AUWorkersEdit = (props: any) => {
  //const {id} = props;
  const [open, setOpen] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [resultSuccess, setResultSuccess] = useState(true);

  const alertSet = (msg: string, status: boolean) => {
    setResultMessage(msg);
    setResultSuccess(status);
    handleClick();
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div style={{ width: "100%" }}>
        <Typography variant="h6">Параметры рабочего места</Typography>
      </div>

      <EditWorker {...props} />
    </div>
    //             <div style={{width: '100%'}}>
    //             {open && <Alert onClose={handleClose} severity={resultSuccess ? "success" : "error"}>
    //                 {resultMessage}
    //             </Alert>
  );
};

/*
<WorkplacesList UserId={id} alertSet={alertSet} />
*/

export const PostCreateActions = () => (
  <TopToolbar>
    <ListButton
      resource="Worker_All"
      label="Вернуться к списку рабочих мест"
    />
  </TopToolbar>
);

export const AUWorkersCreate = (props: any) => (
  <Create
    title="Создание нового рабочего места: - "
    actions={<PostCreateActions />}
  >
    <CreateWorker />
  </Create>
);

export const AUWorkersFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label="№ (код)" source="id" alwaysOn />
    <NumberInput label="№ Юнита" source="Unit_Id" alwaysOn />
    <TextInput label="Расположение" source="Worker_Place_Name" alwaysOn />
    <TextInput label="Для табло" source="Worker_Place_Short" alwaysOn />
    <TextInput label="№ месторасположения" source="Place_id" alwaysOn />
    <SelectInput
      label="Роль"
      choices={roleChoices}
      emptyText="Выберите роль"
      source="Role_Id"
      alwaysOn
    />
  </Filter>
);
// <SelectInput label="Код роли" choices={roleChoices} emptyText="Выберите роль" source="Role_Id" alwaysOn />

export const WorkerAllList = (props: any) => (
  <List
    {...props}
    title=" Рабочие места"
    perPage={25}
    filters={<AUWorkersFilter />}
    sort={{ field: "id", order: "ASC" }}
  >
    <Datagrid isRowSelectable={() => false }>
      <EditButton />
      <NumberField source="id" label="№ (код) " />
      <TextField source="Worker_Place_Name" label="Наименование" />
      <TextField source="Worker_Place_Short" label="Для табло" />
      <NumberField label="№ Юнита" source="Unit_Id" />
      <NumberField source="Unit_Name" label="Юнит" />
      <NumberField source="Number_On_Unit" label="Номер в юните" />
      <NumberField source="Place_Id" label="№ месторасположения" />
      <NumberField source="Place_Name" label="Расположение (название)" />
      <NumberField source="Number_On_Plase" label="Номер в расположениие" />
      <NumberField source="Role_Id" label="Код роли" />
      <NumberField source="Role_Label" label="Допустимая роль пользователя" />

    </Datagrid>
  </List>
);
