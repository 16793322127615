/* eslint-disable react/jsx-pascal-case */
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  EditButton,
  CreateButton,
  DeleteButton,
  Button,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  Create,
  Filter,
  NumberInput,
  TabbedForm,
  FormTab,
  BooleanInput,
  BooleanField,
  useRecordContext,
  BulkDeleteButton,
} from "react-admin";
import { Fragment } from "react/jsx-runtime";
import HandymanIcon from '@mui/icons-material/Handyman';

/* 
  "Mdoc_Id": "8ae354a2-bacc-11ee-a227-eb098972fe1d",
  "Location_Code": 106010033,  
  "Dir_Location": "корпус СМП / этаж 1й этаж / СМП /  Желтая зона / Бокс 06",
  "Location_Name": "06",    
  "Bed_Id": 92804,
  "Bed_Code": "Место-2",
  "Surname": "КНЯЗЬ",
  "Placement_Method": "isBed"
  "Is_Ward": false,
*/

export const Check_and_Fix_MDocs_not_in_DispatcherGetList = ({ ...props }) => {
  //console.log('props', props);
  // const record = useRecordContext();
  const PostBulkActionButtons = () => (
    <Fragment>
        <BulkDeleteButton label="FIX" icon={<HandymanIcon/>}/>
    </Fragment>
  );    
  return (
  <List
    {...props}
    //title="Койки. Диспечер -> КИС"
    title="Список пациентов в Диспетчере, которых уже нет в КИСе и надо освободить койку"
    // filters={<UnitRole_ConfigFilter />}
    perPage={25}
  >
    {/* <CreateButton /> */}
    <Datagrid isRowSelectable={() => false}>
      {/* <EditButton />
      <DeleteButton /> */}      
      <TextField source="Patient_Num" label="№ карты" />
      <TextField source="id" label="id пациента" />
      <TextField source="Location_Code" label="код локации" />      
      <TextField source="Dir_Location" label="путь" />
      {/* <TextField source="Location_Name" label="локация" /> */}
      <TextField source="Bed_Id" label="id койки" />
      <TextField source="Bed_Code" label="койка" />
      <TextField source="Surname" label="фамилия" />
      <TextField source="Placement_Method" label="размещение" />
      <DeleteButton label="FIX" icon={<HandymanIcon/>} />
    </Datagrid>
  </List>
)};
