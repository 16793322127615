import { InferProps, Requireable, ReactElementLike, ReactNodeLike } from 'prop-types';

import {
    List,
    Datagrid,
    TextField,
    EditButton,
    NumberField,
    //DateField,
    Edit,
    Create,
    SimpleForm,
    NumberInput,
    TextInput,
    //TopToolbar,
    //ListButton,
    Filter,
    TabbedForm,
    FormTab,
    ReferenceManyField, required, EditProps
} from 'react-admin';

//import RichTextInput from 'ra-input-rich-text';


export const UnitCreate = (props: any) => (
    <Create {...props} title="Create new Interface">
        <SimpleForm>
            <NumberInput label="# Unit_Id" source="id"/>
            <TextInput label="Наименование" source="Unit_Name"/>
        </SimpleForm>
    </Create>
);

export const xUnitEdit = (props: any) => (
    <Edit {...props} title="Изменяем Наименование Юнита">
        <SimpleForm>
            <NumberField label="# Unit_Id" source="id"/>
            <TextInput label="Наименование" source="Unit_Name"/>
        </SimpleForm>
    </Edit>
);


export const AUUnitsFilter = (props: any) => (
    <Filter {...props}>
        <NumberInput label="№ Юнита" source="id" alwaysOn/>
        <TextInput label="Наименование" source="Unit_Name" alwaysOn/>
    </Filter>
);
// <SelectInput label="Код роли" choices={roleChoices} emptyText="Выберите роль" source="Role_Id" alwaysOn />

export const UnitEdit = (props: any ) => (
    <Edit {...props}>
        <TabbedForm>
            <FormTab label="Параметры узла бизнес-процесса">
                <TextInput disabled label="№ Юнита" source="id" />
                <TextInput multiline source="Unit_Name" label="Наименование" validate={required()} />
                <NumberInput  source="Max_Retry_Count" label="Количество вызовов"
                              helperText="количество повторных нажатий `Следующий`, после которых пациент уйдет в неявку" validate={required()} />
                <NumberInput  source="Rery_Interval" label="Интервал между вызовами"
                              helperText="допустимое количество секунд межлу нажатиями `Следующий`, на которые должна реагировать система (отключено) "validate={required()} />
            </FormTab>

            <FormTab label="Рабочие места">
                <ReferenceManyField reference="Worker_All" target="Unit_Id" filter={{  Role_Id: 4 }} >
                    <Datagrid>
                        <NumberField source="Place_Id" label='№ Месторасположения' />
                        <NumberField source="Place_Name" label='Месторасположение' />
                        <TextField source="Worker_Place_Name" label='Наименование' />
                        <TextField source="Worker_Place_Short" label='Для табло' />
                        <NumberField source="Number_On_Unit" label='Номер в юните' />
                        <NumberField source="Number_On_Plase" label='Номер в месторасположении' />
                        <NumberField source="Role_Id" label='Код роли' />
                        <NumberField source="Role_Name" label='Роль' />
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const  UnitList = (props: any) => 
        <List  { ...props } 
            title="Юниты" 
            perPage={25} 
            filters={<AUUnitsFilter />}
            sort={{ field: 'id', order: 'ASC' }}
        >
            <Datagrid isRowSelectable={() => false }>
                <EditButton />
                <NumberField source="id" label='№ Юнита' />
                <TextField source="Unit_Name" label='Наименование' />
            </Datagrid>
        </List>;