import type { FC } from "react";

import { Edit, SimpleForm, NumberInput, TextInput } from "react-admin";


export const EditPlace: FC = (props: any) => 
  //console.log('ppp', props);
   (
  <Edit title="Изменение параметров месторасположения группы рабочих мест в мед.учреждении">
    <SimpleForm>
      <NumberInput label="ID места" source="id" aria-readonly disabled />
      <TextInput label="Наименование месторасположения группы рабочих мест" required source="Name" />
      <NumberInput label="Число рабочих мест" source="Qty_Work_Places" />
      <TextInput label="Тип" source="Target_Name" required helperText="Краткое название рабочих мест, например, ОКНО, КАБИНЕТ..."/>
    </SimpleForm>
  </Edit>
);
