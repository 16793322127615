/* eslint-disable react/jsx-pascal-case */
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  EditButton,
  CreateButton,
  DeleteButton,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  Create,
  Filter,
  NumberInput,
  TabbedForm,
  FormTab,
  BooleanInput,
  BooleanField,
} from "react-admin";

export const Medical_Profile_GetList = ({ ...props }) => {
  console.log('props', props);
  return (
  <List
    {...props}
    title="Мед профили"
    // filters={<UnitRole_ConfigFilter />}
    perPage={25}
  >
    <CreateButton />
    <Datagrid isRowSelectable={() => false}>
      <EditButton />
      {/* <TextField source="Profile_Code" label="код профиля" /> */}
      <TextField source="Profile_Name" label="имя профиля" />
      {/* <TextField source="Naz_View" label="Naz_View, Идентификатор" /> */}
      <DeleteButton />
    </Datagrid>
  </List>
)};
