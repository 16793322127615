import type { FC } from "react";
import { Typography } from "@mui/material";
import {
  ReferenceInput,
  SelectInput,
  SimpleForm,
  Create,
  TextInput,
} from "react-admin";

const roleChoices = [
    { id: 1, label: "SysAdmin" },
    { id: 2, label: "Бизнес-администратор" },
    { id: 3, label: "Супервизор UNNIT" },
    { id: 4, label: "Исполнитель Услуги" },
    { id: 5, label: "Табло" },
  ];

export const CreateWorker: FC = () => (
    <div>
      <div>
        <Typography variant="h6">
          Заполните параметры нового рабочего места
        </Typography>
      </div>
      <Create>
        <SimpleForm>
          <ReferenceInput source="Place_Id" reference="Places">
            <SelectInput optionText="Name" label="Месторасположение" required />
          </ReferenceInput>
          <ReferenceInput source="Unit_Id" reference="Unit" label="Юнит">
            <SelectInput optionText="Unit_Name" label="Имя Юнита" required />
          </ReferenceInput>
          <SelectInput
            source="Role_Id"
            optionText="label"
            choices={roleChoices}
            label="Роль пользователя"
            required
          />
          <TextInput source="Worker_Place_Name" label="Название рабочего места" required />
          <TextInput source="Worker_Place_Short" label="Краткое Название" required />
        </SimpleForm>
      </Create>
    </div>
  );

