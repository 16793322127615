/* eslint-disable react/jsx-pascal-case */
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  EditButton,
  CreateButton,
  DeleteButton,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  Create,
  Filter,
  NumberInput,
  TabbedForm,
  FormTab,
  BooleanInput,
  BooleanField,
} from "react-admin";

/*
  "Sort_Surname": "АБДУЛЛИН",
  "Location_Code": "10401000001РИТ1",
  "Bed_Code": "332260-208",  
  "Patient_Num": 332260,  
  "Name": "РАМИЛЬ"
  "Surname": "АБДУЛЛИН",
  "Is_Ward": true,  
*/
export const Check_and_Fix_Beds_on_Hosp_GetList = ({ ...props }) => {
  console.log('props', props);
  return (
  <List
    {...props}
    //title="Койки. Совпадение"
    title="Список коек в 2х базах, совпавших по hosp_bed.bed_id = bed_2_kis.bed_id"
    // filters={<UnitRole_ConfigFilter />}
    perPage={25}
  >
    {/* <CreateButton /> */}
    <Datagrid isRowSelectable={() => false}>
      {/* <EditButton /> */}
      <TextField source="Location_Code" label="код локации" />
      <TextField source="Bed_Code" label="койка" />
      <TextField source="Patient_Num" label="номер пациента" />
      <TextField source="Name" label="Имя" />
      <TextField source="Surname" label="Фамилия" />
      <TextField source="Is_Ward" label="Is Ward" />      
      {/* <DeleteButton/> */}
    </Datagrid>
  </List>
)};
