/* eslint-disable react/jsx-pascal-case */
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  Create,
  Filter,
  NumberInput,
  TabbedForm,
  FormTab,
  BooleanInput,
  BooleanField,
} from "react-admin";
//import { sizing } from '@material-ui/system';
// import {Box} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const Medical_Profile_Add = (props: any) => {
  const classes = useStyles();
  return (
    <Create {...props} title="Новый мед профиль" mutationMode="optimistic">
      <TabbedForm>
        <FormTab label="Добавление мед профиля">
          <div>
            <TextInput required source="Profile_Code" label="код профиля" />
          </div>
          <div>
            <TextInput required source="Profile_Name" label="имя профиля" />
          </div>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
