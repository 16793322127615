import { useState } from 'react';
import { 
    List, 
    Datagrid, 
    TextField, 
    EditButton, 
    NumberField, 
    //DateField, 
    Edit, 
    Create,
    SimpleForm,
    NumberInput,
    TextInput,
    TopToolbar,
    ListButton,
    Filter,
    //SelectInput,
    Toolbar,
} from 'react-admin';
import { Typography } from '@mui/material';
//import MuiAlert from '@mui/material/Alert';
import { CreatePlace } from './CreatePlace';
import { EditPlace } from './EditPlace';

const PlaceEditToolbar = (props: any) => {
    console.log({props});

    return (
    <Toolbar {...props} >
        <></>
    </Toolbar>
)};

/*
function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
*/


export const AUPlaceEdit = (props: any) => {
    //const {id} = props;
    const [open] = useState(false);
    //const [resultMessage, setResultMessage] = useState("");
    //const [resultSuccess, setResultSuccess] = useState(true);
    /*
    const alertSet = (msg: string, status: boolean) => {
        setResultMessage(msg)
        setResultSuccess(status);
        handleClick();
    }
    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    */

    return(
        <EditPlace {...props} actions={<PostCreateActions />} />

    //    <Edit {...props} title="Редактирование места" actions={<PostCreateActions />}>
    //        <SimpleForm toolbar={<PlaceEditToolbar {...props} />}>
    //             <div style={{width: '100%'}}>
    //                 <Typography variant="h6">Параметры места</Typography>
    //                 <Typography variant="body2">
    //                     Posts will only be published once an editor approves them
    //                 </Typography>
    //             </div>

    //             <EditPlace {...props} />
                
    //             <div style={{width: '100%'}}>
    //             {open}
    //             </div>
    //        </SimpleForm>
    //    </Edit>
   );
}

export const PostCreateActions = () => (
    <TopToolbar>
        <ListButton resource="Places" label="Вернуться к списку месторасположений" />
    </TopToolbar>
);

export const AUPlacesCreate = (props: any) => (
       <CreatePlace {...props} title="Создание месторасположения группы рабочих мест польльзователей" actions={<PostCreateActions />} />
   );

export const AUPlacesFilter = (props: any) => (
   <Filter {...props}>
     <NumberInput label="#Идентификатор" source="id" alwaysOn/>
     <TextInput label="Наименование месторасположения" source="Name" alwaysOn/>
     <TextInput label="Тип мест" source="Target_Name" alwaysOn/>
   </Filter>
);

/*

*/

export const  PlacesList = (props: any) => 
        <List  { ...props } 
            title="Месторасположения групп рабочих мест"
            perPage={25} 
            filters={<AUPlacesFilter />}
            sort={{ field: 'Name', order: 'ASC' }}
        >
            <Datagrid isRowSelectable={() => false }>
                <EditButton />
                <NumberField source="id" label='# Идентификатор' />
                <TextField source="Name" label='Наименование' />
                <NumberField source="Qty_Work_Places" label='Число рабочих мест' />                   
                <TextField source="Target_Name" label='Тип мест'/>                   
            </Datagrid>
        </List>;

/*
<NumberField source="Unit_Id" label='ID Unit' />                
*/       