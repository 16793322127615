/* eslint-disable react/jsx-pascal-case */
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  EditButton,
  CreateButton,
  DeleteButton,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  Create,
  Filter,
  NumberInput,
  TabbedForm,
  FormTab,
  BooleanInput,
  BooleanField,
  BulkDeleteButton,
} from "react-admin";
import { Fragment } from "react/jsx-runtime";
import HandymanIcon from '@mui/icons-material/Handyman';

/*
  http://kommunarka-tpak:8085/MsgBusService/InternalRestApi/apiSQLRequest/Check_and_Fix_BedId_not_in_KISGetList?_Filter=%7B%7D&_end=25&_order=ASC&_sort=&_start=0&_usrToken=EB87BF815F759DF3D6F1AC777C020BA3
  Check_and_Fix_BedId_not_in_KISGetList  

  "Mdoc_Id": "9bc00ddd-f0f9-11eb-b76d-001dd8b71c26",
  "Location_Code": 106010034,  
  "Dir_Location": "корпус СМП / этаж 1й этаж / СМП /  Желтая зона / Бокс 07",
  "Bed_Id": 92795,
  "Bed_Code": "Место-1",
  "Is_Ward": false,  
  "Surname": "АБАЙДУЛЛИНА"      
*/

export const Check_and_Fix_BedId_not_in_KISGetList = ({ ...props }) => {
  //console.log('props', props);
  const PostBulkActionButtons = () => (
    <Fragment>
        <BulkDeleteButton label="FIX" icon={<HandymanIcon/>}/>
    </Fragment>
  );  
  return (
  <List
    {...props}
    //title="Пациенты. КИС -> Диспечер"
    title="Список коек в КИС, которые отсутствуют в Диспетчере"
    // filters={<UnitRole_ConfigFilter />}
    perPage={25}
  >
    {/* <CreateButton /> */}
    <Datagrid isRowSelectable={() => false}>
      {/* <EditButton /> */}
      {/* <TextField source="Patient_Num" label="№ карты" /> */}
      <TextField source="Mdoc_Id" label="id пациента" />
      <TextField source="Location_Code" label="код локации" />
      <TextField source="Dir_Location" label="путь" />
      <TextField source="id" label="id койки" />
      <TextField source="Bed_Code" label="койка" />
      <TextField source="Surname" label="Фамилия" />
      <TextField source="Is_Ward" label="Is Ward" />      
      <DeleteButton label="FIX" icon={<HandymanIcon/>} />
    </Datagrid>
  </List>
)};
