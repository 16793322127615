/* eslint-disable react/jsx-pascal-case */
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  Create,
  Filter,
  NumberInput,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  ReferenceInput,
  SelectArrayInput,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
} from "react-admin";
//import { sizing } from '@material-ui/system';
// import {Box} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Box, Typography } from "@mui/material";
import { UnitQueueAside } from "./Unit_Queue_Aside";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});
const Aside = () => 
  // console.log("a", this);

   (
    <SimpleForm
      // resource="Message_Queue_4_Link"
      onSubmit={(data: any) => console.log(data)}
      // toolbar={false}
    >
      <Box sx={{ width: "200px", margin: "1em" }}>
        <Typography variant="h6">Добавить связь с очередью</Typography>
        <Typography variant="body2">Выбрать из свободных вариантов</Typography>

        <ReferenceInput reference="Message_Queue_4_Link" source="id">
          {/* <SelectArrayInput optionText="Msg_Type_Own" optionValue="Queue_Id" /> */}
          <SelectInput
            optionText="Queue_Name"
            optionValue="Link_Unit_Queue_Id"
            label="Очередь следующего Юнита"
          />
          {/* <AutocompleteInput optionText="Queue_Name" optionValue="Link_Unit_Queue_Id" label="Queue"/> */}
          {/* <SelectArrayInput optionText="id" optionValue="id" /> */}
        </ReferenceInput>
        {/* <BooleanInput source="Is_Alternatative" label="Is_Alternatative" /> */}
      </Box>
    </SimpleForm>
  )
;

export const Unit_QueueEdit = () => (
  <Edit title="Измение параметров очереди" aside={<UnitQueueAside />}>
    <TabbedForm>
      <FormTab label="Параметры очереди">
        <div>
          Id очеререди: <NumberField source="id" label="Id очеререди" />
        </div>
        <div>
          № участка: <NumberField source="Unit_Id" label="№ Юнита" />
        </div>
        <div>
          <TextField source="Unit_Name" label="Наименование Юнита" />
        </div>

        <TextInput source="Queue_Name" label="Наименование очереди" />
        <div>
          Eсть связанные очереди:{" "}
          <BooleanField source="Is_Linked" label="есть связанные очереди" />
        </div>
        <div>
          Автомат.переход:{" "}
          <BooleanInput source="Is_Automagically" label="Автомат.переход" />
        </div>
        <TextInput
          source="Integration_Code_4_Q_Select"
          label="Связь с интеграцией (коды из Json)"
        />
        <div>
          <TextInput disabled source="Queue_Direction" label="тип Очереди" />
        </div>
      </FormTab>
      <FormTab label="Связи между очередями">
        <ReferenceManyField reference="Linked_Unit_Queue" target="Unit_Queue_Id">
          <Datagrid>
            <TextField source="Label_Q_To" label="Очередь-куда" />
            <TextField
              source="Link_Queue_Name"
              label="Наименование очереди куда"
            />
            <BooleanField source="Is_Alternatative" label="признак ветвления" />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
);

const Unit_QueueFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput source="Unit_Id" label="№ Юнита" alwaysOn/>
    <TextInput source="Queue_Name" label="Наименование очереди" alwaysOn />
    <TextInput source="Unit_Name" label="Наименование Юнита" alwaysOn />
    <TextInput source="Queue_Direction" label="тип Очереди" alwaysOn />
  </Filter>
);

export const Unit_QueueList = ({ ...props }) => (
  <List
    {...props}
    title="Очереди, привязанные к Юнитам"
    filters={<Unit_QueueFilter />}
    sort={{ field: "Unit_Id", order: "ASC" }}
    perPage={25}
  >
    <Datagrid isRowSelectable={() => false}>
      <EditButton />
      <NumberField source="Unit_Id" label="№ Юнита" />
      <TextField source="Unit_Name" label="Наименование Юнита" />
      <NumberField source="id" label="Id очеререди" />
      <TextField source="Queue_Name" label="Наименование очереди" />
      <BooleanField source="Is_Linked" label="есть связанные очереди" />
      <BooleanField source="Is_Automagically" label="Автомат.переход" />
      <TextField
        source="Integration_Code_4_Q_Select"
        label="связь с интеграцией"
      />
      <TextField source="Queue_Direction" label="тип Очереди" />
    </Datagrid>
  </List>
);
