import React from "react";
import { Admin, Resource } from "react-admin";
import AllInboxOutlinedIcon from "@mui/icons-material/AllInboxOutlined";
//import { usePermissions } from 'react-admin';
import ViewListIcon from "@mui/icons-material/ViewList";
import CallToActionTwoToneIcon from "@mui/icons-material/CallToActionTwoTone";
import AppsIcon from "@mui/icons-material/Apps";
import HotelIcon from '@mui/icons-material/Hotel';
import BedroomChildIcon from '@mui/icons-material/BedroomChild';
import BedroomChildOutlinedIcon from '@mui/icons-material/BedroomChildOutlined';
import MasksOutlinedIcon from '@mui/icons-material/MasksOutlined';
import MasksIcon from '@mui/icons-material/Masks';
import TableChartIcon from "@mui/icons-material/TableChart";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import UserIcon from "@mui/icons-material/People";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import BusinessIcon from "@mui/icons-material/Business";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import ContactsIcon from "@mui/icons-material/Contacts";
import EngineeringIcon from "@mui/icons-material/Engineering";
import MediationIcon from "@mui/icons-material/Mediation";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
// eslint-disable-next-line import/no-extraneous-dependencies
import polyglotI18nProvider from 'ra-i18n-polyglot';
import raDataJsonServer from "../data-provider/raDataJsonServer";
import authProvider from "../authProvider";
import Dashboard from "../Dashboard";
import russianMessages from "../ra-language-russian";
import {
  UserList,
  AUUsersEdit,
  AUUsersCreate,
  Order4QList,
  Order4QShow,
  PlacesList,
  AUPlacesCreate,
  AUPlaceEdit,
  WorkerAllList,
  AUWorkersCreate,
  AUWorkersEdit,
  MessageTypes,
  MessageTypesCreate,
  MessageTypesEdit,
  MessageIntefaces,
  MessageIntefaceCreate,
  MessageIntefaceEdit,
  MessageDirections,
  Tableau_VwList,
  Tableau_VwEdit,
  NamedUnitList,
  NamedUnitListShow,
  MessageQueue,
  MessageQueueEdit,
  Medical_Profile_GetList,  
  Medical_Profile_Put,
  Medical_Profile_Add,
  PermissionsGetList,
  PermissionsGetOne,
  PermissionsPut,
  Check_and_Fix_Beds_on_Hosp_GetList,
  Check_and_Fix_MDocs_not_in_KISGetList,
  Check_and_Fix_BedId_not_in_KISGetList,
  Check_and_Fix_MDocs_not_in_DispatcherGetList,
  Check_and_Fix_BedId_not_in_DispatcherGetList,  

  //MessageDirectionsCreate
  // Place2Woker4UsrIdGetList,
} from "../components";
import { StatReportA } from "../components/StatReportA";
import {
  MessageDirectionsCreate,
  MessageDirectionsEdit,
} from "../components/MessageDirections";
import { UnitList, UnitEdit, UnitCreate } from "../components/UnitList";
import { MessageTemplates } from "../components/MessageTemplates/MessageTemplates";
import { MessageTemplateEdit } from "../components/MessageTemplates/MessageTemplateEdit";
import {
  WorkerHierarchyList,
  WorkerHierarchyShow,
} from "../components/WorkerHierarchy";
import {
  UnitRole_ConfigList,
  UnitRole_ConfigEdit,
} from "../components/UnitRole_Config";
import {
  Unit_QueueList,
  Unit_QueueEdit,
} from "../components/Unit_Queue/Unit_Queue";
import {
  Linked_Unit_QueueList,
  Linked_Unit_QueueEdit,
} from "../components/Linked_Unit_Queue/Linked_Unit_Queue";
import { RouterConfiguration } from "../constants/router-cfg";
// type x_permissions = string;
const dataProvider = raDataJsonServer(`${process.env.REACT_APP_APIURL}`);

// @ts-ignore
const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru', { allowMissing: true }) ;
const isDoShowHospital = process.env.REACT_APP_IS_DO_SHOW_HOSPITAL;

const doShowHospital= ( isShowHospital: boolean ) => {
  if ( isShowHospital )
  return (
      <Resource
          name="AU_Users"
          options={{ label: "Пользователи" }}
          list={UserList}
          edit={AUUsersEdit}
          create={AUUsersCreate}
          icon={UserIcon}
      />
  );
  else return ( <div />)
}
const Router = () => {
  //const { loaded, permissions } = usePermissions();
  const RoleLabel = localStorage.getItem("Role_Label");
  //console.error( "try usePermissions", {permissions});
  //console.error( "is loaded", {loaded});
  let isShowHospital: boolean = false;
  console.log({isDoShowHospital}, process.env.REACT_APP_IS_DO_SHOW_HOSPITAL);
  if ( isDoShowHospital ==='true') isShowHospital = false;
   else isShowHospital = true;

  console.warn("show RoleLabel", RoleLabel);
  console.warn("show isDoShowHospital", isDoShowHospital);
  console.warn("show isShowHospital", isShowHospital);
  if ( isShowHospital === true )
  return (
    <Admin
      disableTelemetry
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      dashboard={Dashboard}
      title="toDo"
    >
      { RouterConfiguration.AU_Users && <Resource
        name="AU_Users"
        options={{ label: "Пользователи" }}
        list={UserList}
        edit={AUUsersEdit}
        create={AUUsersCreate}
        icon={UserIcon}
      />
      }
      { RouterConfiguration.Order_4_Q && <Resource
        name="Order_4_Q"
        options={{ label: "Талоны" }}
        list={Order4QList}
        edit={Order4QShow}
        icon={AddToPhotosIcon}
      />
      }
      { RouterConfiguration.StatReport_N && <Resource
        name="StatReport_N"
        options={{ label: "Отчет за.." }}
        list={StatReportA}
        icon={TableChartIcon}
      />
      }
      { RouterConfiguration.StatReport_N && <Resource
        name="Places"
        options={{ label: "Расположения рабочих мест" }}
        list={PlacesList}
        create={AUPlacesCreate}
        edit={AUPlaceEdit}
        icon={BusinessIcon}
      />
    }
    { RouterConfiguration.Unit_All && <Resource
        name="Unit_All"
        options={{ label: "Участки (Юниты)" }}
        list={UnitList}
        create={UnitCreate}
        edit={UnitEdit}
        icon={FlipCameraAndroidIcon}
      />
    }
    { RouterConfiguration.UnitRole_Config && <Resource
        name="UnitRole_Config"
        options={{ label: "UI компоненты Юнит" }}
        list={UnitRole_ConfigList}
        edit={UnitRole_ConfigEdit}
        icon={AppsIcon}
      />
    }
    { RouterConfiguration.Unit_Queue && <Resource
        name="Unit_Queue"
        options={{ label: "Очереди Юнит-ов" }}
        list={Unit_QueueList}
        edit={Unit_QueueEdit}
        icon={AutoAwesomeMotionIcon}
      />
    }
    { RouterConfiguration.Linked_Unit_Queue && <Resource
        name="Linked_Unit_Queue"
        options={{ label: "Связи между очередями" }}
        list={Linked_Unit_QueueList}
        edit={Linked_Unit_QueueEdit}
        icon={CallSplitIcon}
      />
    }
    { RouterConfiguration.Worker_All && <Resource
        name="Worker_All"
        options={{ label: "Рабочие места" }}
        list={WorkerAllList}
        create={AUWorkersCreate}
        edit={AUWorkersEdit}
        icon={CoPresentIcon}
      />
    }
    { RouterConfiguration.Admin_Worker_Hierarchy && <Resource
        name="Admin_Worker_Hierarchy"
        options={{ label: "Группы иерархий" }}
        list={WorkerHierarchyList}
        show={WorkerHierarchyShow}
        icon={BusinessIcon}
      />
    }
    { RouterConfiguration.Daughter_Worker_Hierarchy && <Resource
          name="Daughter_Worker_Hierarchy"
          options={{ label: "Daughter_Worker_Hierarchy" }}
          icon={TableChartIcon}
      />
    }
    { RouterConfiguration.Tableau_Vw && <Resource
          name="Tableau_Vw"
          options={{ label: "Табло" }}
          list={Tableau_VwList}
          edit={Tableau_VwEdit}
          icon={AppsIcon}
      />
    }
    { RouterConfiguration.Named_Unit_List && <Resource
          name="Named_Unit_List"
          options={{ label: "Метки Юнит-ов" }}
          list={NamedUnitList}
          show={NamedUnitListShow}
          icon={EngineeringIcon}
      />
    }
    { RouterConfiguration.Permissions && <Resource
        name="Permissions"
        options={{ label: "Права по ролям" }}
        list={PermissionsGetList}
        edit={PermissionsPut}
        // icon={AppsIcon}
      />
      }
      { RouterConfiguration.Medical_Profile && <Resource
        name="Medical_Profile"
        options={{ label: "Мед. профили" }}
        list={Medical_Profile_GetList}
        edit={Medical_Profile_Put}
        create={Medical_Profile_Add}
        icon={AppsIcon}
      />
      }
      { RouterConfiguration.Check_and_Fix_Beds_on_Hosp && <Resource
        name="Check_and_Fix_Beds_on_Hosp_"
        options={{ label: "Койки. Совпадение" }}
        list={Check_and_Fix_Beds_on_Hosp_GetList}                
        icon={HotelIcon}
      />
      }            
      { RouterConfiguration.Check_and_Fix_MDocs_not_in_KIS  && <Resource
        name="Check_and_Fix_MDocs_not_in_KIS"
        options={{ label: "Койки. КИС -> Диспечер" }}
        list={Check_and_Fix_MDocs_not_in_KISGetList}                
        icon={MasksIcon}
      />
      }
      { RouterConfiguration.Check_and_Fix_BedId_not_in_KIS && <Resource
        name="Check_and_Fix_BedId_not_in_KIS"
        options={{ label: "Пациенты. КИС -> Диспечер" }}
        list={Check_and_Fix_BedId_not_in_KISGetList}                
        icon={BedroomChildIcon}
      />
      }
      { RouterConfiguration.Check_and_Fix_MDocs_not_in_Dispatcher  && <Resource
        name="Check_and_Fix_MDocs_not_in_Dispatcher"
        options={{ label: "Койки. Диспечер -> КИС" }}
        list={Check_and_Fix_MDocs_not_in_DispatcherGetList}                
        icon={MasksOutlinedIcon}
      />
      }
      { RouterConfiguration.Check_and_Fix_BedId_not_in_Dispatcher && <Resource
        name="Check_and_Fix_BedId_not_in_Dispatcher"
        options={{ label: "Пациенты. Диспечер -> КИС" }}
        list={Check_and_Fix_BedId_not_in_DispatcherGetList}                
        icon={BedroomChildOutlinedIcon}
      />
      }      
    { RouterConfiguration.MessageQueue && <Resource
        name="MessageQueue"
        options={{ label: "Очереди" }}
        list={MessageQueue}
        edit={MessageQueueEdit}
        icon={AllInboxOutlinedIcon}
      />
    }
    { RouterConfiguration.MessageIntefaces && <Resource
        name="MessageIntefaces"
        options={{ label: "Интерфейсы" }}
        list={MessageIntefaces}
        edit={MessageIntefaceEdit}
        create={MessageIntefaceCreate}
        icon={MediationIcon}
      />
    }
    { RouterConfiguration.MessageTypes && <Resource
        name="MessageTypes"
        options={{ label: "Типы" }}
        list={MessageTypes}
        edit={MessageTypesEdit}
        create={MessageTypesCreate}
        icon={SyncAltIcon}
      />
    }
    { RouterConfiguration.MessageTemplates && <Resource
        name="MessageTemplates"
        options={{ label: "Шаблоны" }}
        list={MessageTemplates}
        edit={MessageTemplateEdit}
        icon={DynamicFormIcon}
      />
    }
    { RouterConfiguration.MessageDirections && <Resource
        name="MessageDirections"
        options={{ label: "Системы" }}
        list={MessageDirections}
        edit={MessageDirectionsEdit}
        create={MessageDirectionsCreate}
        icon={CallToActionTwoToneIcon}
      />
    }
    { RouterConfiguration.MessageQueueLog && <Resource
        name="MessageQueueLog"
        options={{ label: "MessageQueueLog" }}
        icon={TableChartIcon}
      />
      }
      { RouterConfiguration.MessageQueueDet && <Resource
        name="MessageQueueDet"
        options={{ label: "MessageQueueDet" }}
        icon={TableChartIcon}
      />
      }
      { RouterConfiguration.MessageTemplatesEntry && <Resource
        name="MessageTemplatesEntry"
        options={{ label: "MessageTemplatesEntry" }}
        icon={TableChartIcon}
      />
      }
      { RouterConfiguration.Message_Queue_4_Link && <Resource
        name="Message_Queue_4_Link"
      />
      }
    </Admin>
  );
  else
    return (
        <Admin
            disableTelemetry
            dataProvider={dataProvider}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            dashboard={Dashboard}
            title="toDo"
        >
          <Resource
              name="AU_Users"
              options={{ label: "Пользователи" }}
              list={UserList}
              edit={AUUsersEdit}
              create={AUUsersCreate}
              icon={UserIcon}
          />
          <Resource
              name="MessageQueue"
              options={{ label: "Очереди" }}
              list={MessageQueue}
              edit={MessageQueueEdit}
              icon={AllInboxOutlinedIcon}
          />
          <Resource
              name="MessageIntefaces"
              options={{ label: "Интерфейсы" }}
              list={MessageIntefaces}
              edit={MessageIntefaceEdit}
              create={MessageIntefaceCreate}
              icon={MediationIcon}
          />
          <Resource
              name="MessageTypes"
              options={{ label: "Типы" }}
              list={MessageTypes}
              edit={MessageTypesEdit}
              create={MessageTypesCreate}
              icon={SyncAltIcon}
          />
          <Resource
              name="MessageTemplates"
              options={{ label: "Шаблоны" }}
              list={MessageTemplates}
              edit={MessageTemplateEdit}
              icon={DynamicFormIcon}
          />
          <Resource
              name="MessageDirections"
              options={{ label: "Системы" }}
              list={MessageDirections}
              edit={MessageDirectionsEdit}
              create={MessageDirectionsCreate}
              icon={CallToActionTwoToneIcon}
          />
          <Resource
              name="MessageQueueLog"
              options={{ label: "MessageQueueLog" }}
              icon={TableChartIcon}
          />
          <Resource
              name="MessageQueueDet"
              options={{ label: "MessageQueueDet" }}
              icon={TableChartIcon}
          />
          <Resource
              name="MessageTemplatesEntry"
              options={{ label: "MessageTemplatesEntry" }}
              icon={TableChartIcon}
          />

          <Resource
              name="Message_Queue_4_Link"
          />
        </Admin>
    );
};

export { Router };
