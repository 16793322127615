/* eslint-disable react/jsx-pascal-case */
import { useState, useEffect } from "react";
import {
  SimpleForm,
  SelectInput,
  SaveButton,
  BooleanInput,
  Toolbar,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { Box, Typography } from "@mui/material";

import * as Agent from "../../services/ConnectionsServices";

type QueryListItem = {
  Link_Unit_Queue_Id: number;
  Queue_Name: string;
  Unit_Queue_Id: number;
};

const CustomToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const UnitQueueAside = () => {
  const [isLoading, setIsLoading] = useState(true);
  const notify = useNotify();
  const record = useRecordContext();
  const [queryList, setQueryList] = useState<QueryListItem[]>([]);
  const refresh = useRefresh();

  const localStorageusrToken: string | undefined | null =
  sessionStorage.getItem("_usrToken");

  useEffect(() => {
    if (!!localStorageusrToken && localStorageusrToken !== null && record?.id) {
      Agent.MessageQueue4Link.getList(
        Number(record?.id),
        localStorageusrToken
      ).then((res) => {
        setQueryList(res);
        return setIsLoading(false);
      })
      .catch((err: any) => {
        notify(
            `Error: MessageQueue4Link not fetched: ${err.resultMessage.toString()}`,
            { type: "error" }
          );
      });
    }
  }, [record]);

  const postSubmit = (props: any) => {
    const { queue_id, id, Is_Alternatative: isAlternative } = props;

    if (!!localStorageusrToken && localStorageusrToken !== null && record?.id) {
      Agent.LinkedUnitQueue.add(queue_id, id, isAlternative, localStorageusrToken).catch((err: any) => {
        notify(
            `Error: LinkedUnitQueueAdd not fetched: ${err.resultMessage.toString()}`,
            { type: "error" }
          );
      })
      .finally(() => refresh());
    }
  };

  return (
    <SimpleForm
      onSubmit={postSubmit}
      toolbar={<CustomToolbar />}
    >
      <Box sx={{ width: "200px", margin: "1em" }}>
        <Typography variant="h6">Добавить связь с очередью следующего Юнита</Typography>
        <Typography variant="body2">Выбрать из свободных вариантов</Typography>
        {!isLoading ? (
          <SelectInput
            choices={queryList}
            optionText="Queue_Name"
            optionValue="Link_Unit_Queue_Id"
            label="Очередь Юнита"
            source="queue_id"
            required
          />
        ) : (
          <></>
        )}
        <BooleanInput source="Is_Alternatative" label="Признак ветвления" />
      </Box>
    </SimpleForm>
  );
};
