/* eslint-disable react/jsx-pascal-case */
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  Create,
  Filter,
  NumberInput,
  TabbedForm,
  FormTab,
  BooleanInput,
  BooleanField,
} from "react-admin";
//import { sizing } from '@material-ui/system';
// import {Box} from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const UnitRole_ConfigEdit = (props: any) => {
  const classes = useStyles();
  return (
    <Edit {...props} title="Изменение параметров UI-конфигурации Юнита" mutationMode="optimistic">
      <TabbedForm>
        <FormTab label="UI-конфигкрация">
          <div>
          <NumberInput disabled source="Unit_Id" label="№ Юнита, Идентификатор" />

              <TextInput disabled source="Unit_Name" label="Наименование Юнита" helperText="шаг бизнес процесса"/>

          </div>
          <div>
          <NumberInput disabled source="Role_Id" label="Id Роли Системы" />

            <strong>
              <TextInput disabled  source="Role_Label" label="Код роли системы" />
            </strong>
          </div>
          <BooleanInput
            source="Orderentryqueuegetlist"
            label="?показывать свою входную очередь"
            helperText="признак - надо ли показывать свою входную очередь - Обязательно!"
          />
          <BooleanInput
            source="Orderpostpoundqueuegetlist"
            label="?показывать свою очередь Неявка"
            helperText="признак - надо ли показывать очередь неявки своего Юнита "
          />
          <BooleanInput
            source="Nextentryworkersqueuegetlist"
            label="?показывать входную очередь следующего Юнита"
            helperText="признак - надо ли показывать входную очередь следующего Юнита "
          />
          <BooleanInput
            source="Nextpostpondqueuegetlist"
            label="?Неявка следующего Юнита"
            helperText="признак - надо ли показывать очередь неявки следующего Юнита "
          />
          <BooleanInput source="Controls" label="?Вызов-Приём" helperText="признак - надо ли показывать эдементы управления приёмом "/>

          <BooleanInput source="Nextexecutorgetlist" label="is nextexecutor" helperText="признак - надо ли показывать табло следующего Юнита "/>

        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const UnitRole_ConfigFilter = (props: any) => (
  <Filter {...props}>
    <TextInput source="Role_Label" label="Символьная метка Роли" alwaysOn />
    <TextInput source="Unit_Name" label="Наименование Юнита" alwaysOn />
  </Filter>
);

export const UnitRole_ConfigEditForm = (props: any) => (
  <RowForm>
  <div>
  <NumberInput disabled source="Unit_Id" label="№ Юнита, Идентификатор" />

      <TextInput disabled source="Unit_Name" label="Наименование Юнита" helperText="шаг бизнес процесса"/>

  </div>
  <div>
  <NumberInput disabled source="Role_Id" label="Id Роли Системы" />

    <strong>
      <TextInput disabled  source="Role_Label" label="Код роли системы" />
    </strong>
  </div>
  <BooleanInput
    source="Orderentryqueuegetlist"
    label="?показывать свою входную очередь"
    helperText="признак - надо ли показывать свою входную очередь - Обязательно!"
  />
  <BooleanInput
    source="Orderpostpoundqueuegetlist"
    label="?показывать свою очередь Неявка"
    helperText="признак - надо ли показывать очередь неявки своего Юнита "
  />
  <BooleanInput
    source="Nextentryworkersqueuegetlist"
    label="?показывать входную очередь следующего Юнита"
    helperText="признак - надо ли показывать входную очередь следующего Юнита "
  />
  <BooleanInput
    source="Nextpostpondqueuegetlist"
    label="?Неявка следующего Юнита"
    helperText="признак - надо ли показывать очередь неявки следующего Юнита "
  />
  <BooleanInput source="Controls" label="?Вызов-Приём" helperText="признак - надо ли показывать эдементы управления приёмом "/>

  <BooleanInput source="Nextexecutorgetlist" label="is nextexecutor" helperText="признак - надо ли показывать табло следующего Юнита "/>

</RowForm>
);

export const UnitRole_ConfigList = ({ ...props }) => (
  <List
    {...props}
    title="Активация UI компонент для Юнитов по ролям пользователей"
    filters={<UnitRole_ConfigFilter />}
    perPage={25}
  >
    <EditableDatagrid isRowSelectable={() => false} createForm={<UnitRole_ConfigEditForm />} editForm={<UnitRole_ConfigEditForm />}>
      {/* <EditButton /> */}
      <NumberField source="Unit_Id" label="№ Юнита, Идентификатор" />
      <TextField source="Unit_Name" label="Наименование Юнита" />
      <NumberField source="Role_Id" label="Id Роли Системы" />
      <TextField source="Role_Label" label="Код роли системы" />
      <BooleanField
        source="Orderentryqueuegetlist"
        label="? Входная очередь"
      />
      <BooleanField
        source="Orderpostpoundqueuegetlist"
        label="? Неявка(своя)"
      />
      <BooleanField
        source="Nextentryworkersqueuegetlist"
        label="? Входная следующий шаг"
      />
      <BooleanField
        source="Nextpostpondqueuegetlist"
        label="? Неявка следующий шаг"
      />
      <BooleanField source="Controls" label="? Вызов-Приём" />
      <BooleanField source="Nextexecutorgetlist" label="? Табло исполнителей" />
    </EditableDatagrid>
  </List>
);



//  <List
// {...props}
// title="Активация UI компонент для Юнитов по ролям пользователей"
// filters={<UnitRole_ConfigFilter />}
// perPage={25}
// >
// <Datagrid isRowSelectable={() => false}>
//   <EditButton />
//   <NumberField source="Unit_Id" label="№ Юнита, Идентификатор" />
  // <TextField source="Unit_Name" label="Наименование Юнита" />
  // <NumberField source="Role_Id" label="Id Роли Системы" />
  // <TextField source="Role_Label" label="Код роли системы" />
  // <BooleanField
  //   source="Orderentryqueuegetlist"
  //   label="? Входная очередь"
  // />
  // <BooleanField
  //   source="Orderpostpoundqueuegetlist"
  //   label="? Неявка(своя)"
  // />
  // <BooleanField
  //   source="Nextentryworkersqueuegetlist"
//     label="? Входная следующий шаг"
//   />
//   <BooleanField
//     source="Nextpostpondqueuegetlist"
//     label="? Неявка следующий шаг"
//   />
//   <BooleanField source="Controls" label="? Вызов-Приём" />
//   <BooleanField source="Nextexecutorgetlist" label="? Табло исполнителей" />
// </Datagrid>
// </List>