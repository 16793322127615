/* eslint-disable react/jsx-pascal-case */
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  Create,
  Filter,
  NumberInput,
  TabbedForm,
  FormTab,
  BooleanInput,
  BooleanField,
} from "react-admin";
//import { sizing } from '@material-ui/system';
// import {Box} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const Medical_Profile_Put = (props: any) => {
  const classes = useStyles();
  return (
    <Edit {...props} title="Мед профиль" mutationMode="optimistic">
      <TabbedForm>
        <FormTab label="Редактирование мед профиля">
          {/* <div>
            <TextInput source="Naz_View" label="Naz_View, Идентификатор" />
          </div> */}
          <div>
            <TextInput disabled source="id" label="код профиля" />
          </div>
          <div>
            <TextInput required source="Profile_Name" label="имя профиля" />
          </div>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
