import React from "react";
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    DateField,
    EditButton,
    Show,
    SimpleForm,
    TextInput,
    SelectInput,
    Tab,
    Filter,
    NumberInput, TabbedShowLayout,
    FormTab,
    ReferenceManyField, ShowButton
} from 'react-admin';
//import { sizing } from '@material-ui/system';
// import {Box} from "@mui/material";
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});


export const WorkerHierarchyShow = (props: any) => {
    const classes = useStyles();
    return (
        <Show {...props} title="Контроль параметров Группы иерархий">
            <TabbedShowLayout>
                <Tab  label="Параметры Группы иерархий">
                    <NumberField  source="id" label="Идентификатор уровня"/>
                    <NumberField  source="Parent_Level_Id" label="№ родительский элемент"/>
                    <TextField  source="Worker_Place_Name"  label="Наименование группы"/>
                    <NumberField  source="Unit_Id" label="№ участка, Идентификатор Юнита"/>
                    <TextField  source="Unit_Name" label="Наименование Юнит"/>
                    <NumberField  source="Worker_Id" label="Id рабочего места"/>
                    <NumberField source="Number_On_Unit" label="№ рабочего места в Юните"/>
                    <NumberField  source="Level_Number" label="№ уровня"/>
                </Tab >
                <Tab  label="Дочерние рабочие места и группы">
                    <ReferenceManyField reference="Daughter_Worker_Hierarchy" target="Parent_Level_Id"  >
                        <Datagrid isRowSelectable={() => false} >
                            <NumberField source="id" label="Идентификатор уровня"/>
                            <NumberField source="Parent_Level_Id" label="№ родительский элемент"/>
                            <TextField source="Worker_Place_Name" label="Наименование группы"/>
                            <NumberField source="Unit_Id" label="№ участка, Идентификатор"/>
                            <TextField source="Unit_Name" label="Наименование Юнита"/>
                            <TextField source="Worker_Id" label="Id рабочего места"/>
                            <NumberField source="Number_On_Unit" label="NN в Юните"/>
                            <NumberField source="Level_Number" label="№ уровня"/>
                        </Datagrid>
                    </ReferenceManyField>
                </Tab >
            </TabbedShowLayout>

        </Show>
    );
}

const WorkerHierarchyFilter = (props: any) => (
    <Filter {...props}>
        <NumberInput label="№ участка, Идентификатор Юнита" source="Unit_Id" alwaysOn />
        <TextInput source="Worker_Place_Name" label="Наименование группы" alwaysOn />
        <TextInput source="Unit_Name" label="Наименование Юнита" alwaysOn/>
    </Filter>
);

export const  WorkerHierarchyList = ({ ...props }) => <List
    { ...props } title="Группы иерархий раблочих мест" filters={<WorkerHierarchyFilter /> } perPage={25}
>
    <Datagrid isRowSelectable={() => false } >
        <ShowButton/>
        <NumberField source="id" label="Идентификатор уровня"/>
        <NumberField source="Parent_Level_Id" label="№ родительский элемент"/>
        <TextField source="Worker_Place_Name" label="Наименование группы"/>
        <NumberField source="Unit_Id" label="№ участка, Идентификатор"/>
        <TextField source="Unit_Name" label="Наименование Юнита"/>
        <NumberField source="Worker_Id" label="Id рабочего места"/>
        <NumberField source="Number_On_Unit" label="NN в Юните"/>
        <NumberField source="Level_Number" label="№ уровня"/>
    </Datagrid>
</List>;


