// RouterConfiguration values only 0 and 1 are allowed

export enum RouterConfiguration {
    "AU_Users" = 1,
    "Order_4_Q" = 0,
    "StatReport_N" = 0,
    "Places" = 0,
    "Unit_All" = 0,
    "UnitRole_Config" = 0,
    "Unit_Queue" = 0,
    "Linked_Unit_Queue" = 0,
    "Worker_All" = 1,
    "Admin_Worker_Hierarchy" = 0,
    "Daughter_Worker_Hierarchy" = 0,
    "Tableau_Vw" = 0,
    "Named_Unit_List" = 0,
    "MessageQueue" = 1,
    "MessageIntefaces" = 1,
    "MessageTypes" = 1,
    "MessageTemplates" = 1,
    "MessageDirections" = 1,
    "MessageQueueLog" = 1,
    "MessageQueueDet" = 1,
    "MessageTemplatesEntry" = 1,
    "Message_Queue_4_Link" = 1,
    "Permissions" = 0,
    "Medical_Profile" = 0,
    "Check_and_Fix_Beds_on_Hosp" = 0,    
    "Check_and_Fix_MDocs_not_in_KIS" = 0,
    "Check_and_Fix_BedId_not_in_KIS" = 0,   
    "Check_and_Fix_MDocs_not_in_Dispatcher" = 0,
    "Check_and_Fix_BedId_not_in_Dispatcher" = 0,       
};
