import type { FC } from "react";

import {
  BooleanInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  Edit,
  TextInput, NumberInput,
} from "react-admin";
import React from "react";

const roleChoices = [
  { id: 1, label: "SysAdmin" },
  { id: 2, label: "Бизнес-администратор" },
  { id: 3, label: "Супервизор UNNIT" },
  { id: 4, label: "Исполнитель Услуги" },
  { id: 5, label: "Табло" },
];

export const EditWorker: FC = () => (
    <div className="MuiCardContent-root">
      <Edit title="Редактирование рабочего места">
        <SimpleForm>
          <ReferenceInput source="Place_Id" reference="Places">
            <SelectInput optionText="Name" label="Имя плейса" disabled />
          </ReferenceInput>
          <ReferenceInput source="Unit_Id" reference="Unit" label="Юнит">
            <SelectInput optionText="Unit_Name" label="Имя юнита" disabled />
          </ReferenceInput>
          <SelectInput
            source="Role_Id"
            optionText="label"
            choices={roleChoices}
            label="Исполнитель услуги" required
          />
          <BooleanInput source="Is_Active" />
          <TextInput source="Worker_Place_Name"
            label="Название рабочего места"
          />
          <NumberInput source="Number_On_Plase" label="Номер в месторасположении" required helperText="№ рабочего места в месторасположении, используется в выборке для Табло"/>
          <TextInput source="Worker_Place_Short" label="Краткое Название" required />
        </SimpleForm>
      </Edit>
    </div>
  );
