/* eslint-disable react/jsx-pascal-case */
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  EditButton,
  Edit,
  useEditContext,
  TextInput,
  SelectInput,
  ReferenceInput,
  Filter,
  NumberInput,
  TabbedForm,
  FormTab,
  BooleanInput,
  BooleanField, useRecordContext,
} from "react-admin";
//import { sizing } from '@material-ui/system';
// import {Box} from "@mui/material";
import { makeStyles } from "@mui/styles";
// import {RaRecord} from "ra-core";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

// const makeFiter4Record = ( record4Filter : RaRecord) => {
//   const record4GetFilter = useRecordContext();
//   // filter={makeFiter4Record(record)}
//   if ( !record4Filter ) {
//     console.info("record4Filter:", record4Filter )
//     return {};
//   }
//   if ( record4GetFilter !== undefined) {
//     const filter = {Worker_Id: record4GetFilter.id};
//     console.info("filter:", filter )
//     return filter
//   }
//   else
//   {
//     const filter = {Worker_Id: 'null'};
//     console.info("filter:", filter )
//     return filter
//   }

// }

// <ReferenceInput nane="Login" source="id" reference="Tableau_Users" name={"Worker_Id"}>
export const Tableau_VwEdit = (props: any) => {
  const classes = useStyles();
  //               <SelectInput  label="Пользователь под Табло" optionText={(Tableau_Users: any) =>
  //                   `${Tableau_Users.id} ${Tableau_Users.Login} ${Tableau_Users.Usr_Name}`
  //               }  source="Login" name="Login" optionValue="Login" />
  /*

              <ReferenceInput source="Login" reference="Tableau_Users"  >
              <SelectInput  label="Пользователь под Табло" optionText={(Tableau_Users: any) =>
                  `${Tableau_Users.Login} ${Tableau_Users.Usr_Name}`
              }  source="Login" name="Login" optionValue="Login" />
            </ReferenceInput>

            <ReferenceInput source="Unit_Id" reference="Unit" label="Юнит">
            <SelectInput optionText="Unit_Name" label="Имя юнита" disabled />
          </ReferenceInput>
   */
  return (

    <Edit {...props} title="Изменяем параметры Табло">
      <TabbedForm>
        <FormTab label="Параметры Табло">
          <NumberInput disabled source="id" label="Tableau Id" />
          <div> <NumberInput disabled source="Unit_Id" label="№ участка, Идентификатор" />
            <strong>
              <TextInput disabled source="Unit_Name" label="Наименование Юнита" />
            </strong>
          </div>

            <ReferenceInput source="Login" reference="Tableau_Users"  >
              <SelectInput  label="Пользователь под Табло" optionText={(Tableau_Users: any) =>
                  `${Tableau_Users.id} ( ${Tableau_Users.Usr_Name} )`
              }  source="Login" name="Login" optionValue="id" />
            </ReferenceInput>

          <NumberInput source="Start_Number_4_Vw" label="Начальный № кабинета на Табло" min={1} max={99} defaultValue={1}/>
          <NumberInput source="Numbet_Pos_4_Vw" label="Количество кабинетов на Табло" min={1} max={10} defaultValue={4}/>

          <NumberInput source="Column_Tableau_Count" label="Количество столбцов вывода" min={1} max={2} defaultValue={1}/>
          <TextInput source="Mac_Address" label="MAC" />

          <BooleanInput
              source="Is_Horizontal"
              label="? Горизон."
          />
          <BooleanInput name="Is_Show_Entry"
              source="Is_Show_Entry"
              label="? Показывать очередь"
          />
          <BooleanInput
              source="Is_Show_Postpound"
              label="? Показывать неявку"
          />
          <BooleanInput
              source="Is_Polyclinic"
              label="? Талоны,не №ИБ"
          />
          <BooleanInput
              source="Is_Excort"
              label="? для Сопровожд."
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const Tableau_VwFilter = (props: any) => (
  <Filter {...props}>
    <TextInput name="Mac_Address" source="Mac_Address" label="Mac Address" alwaysOn />
    <TextInput name="Unit_Name" source="Unit_Name" label="Наименование Юнита" alwaysOn />
  </Filter>
);

export const Tableau_VwList = ({ ...props }) => (
  <List
    {...props}
    title="список Табло в Системе"
    filters={<Tableau_VwFilter />}
    perPage={25}
  >
    <Datagrid isRowSelectable={() => false}>
      <EditButton />
      <NumberField source="id" label="Tableau Идентификатор" />
      <NumberField source="Unit_Id" label="№ участка, Идентификатор" />
      <TextField source="Unit_Name" label="Наименование Юнита" />
      <NumberField source="Column_Tableau_Count" label="Column Count" />
      <BooleanField
        source="Is_Horizontal"
        label="? Горизон."
      />
      <BooleanField
        source="Is_Show_Entry"
        label="? Показывать очередь"
      />
      <BooleanField
        source="Is_Show_Postpound"
        label="? Показывать неявку"
      />
      <BooleanField
        source="Is_Polyclinic"
        label="? Талоны,не №ИБ"
      />
      <BooleanField
          source="Is_Excort"
          label="? для Сопровожд."
      />
      <TextField source="Mac_Address" label="MAC" />
      <TextField source="Login" label="Login" />
    </Datagrid>
  </List>
);
